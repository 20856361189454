* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.7;
  background: #eee;
  color: #333;
}

.form-group,
.form-group label,
.form-group input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-group label {
  font-size: 0.8rem;
}

.form-group input {
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

.form-group input:focus {
  border-bottom: 3px solid #861f41;
}

.btn {
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  border: none;
}

.btn:hover {
  opacity: 0.8;
}

.btn-primary {
  background-color: #861f41;
  color: #fff;
}

.error-message {
  width: 100%;
  display: inline-block;
  padding: 5px;
  background: red;
  color: #fff;
  text-align: center;
  margin: 0.5rem 0;
}

.success-message {
  width: 100%;
  display: inline-block;
  padding: 5px;
  background: green;
  color: #fff;
  text-align: center;
  margin: 0.5rem 0;
}

.center {
  margin: auto;
  /* width: 90%; */
  /* border: 3px solid #791c3b; */
  padding: 10px;
  border-radius: 10px;
}

.centerContent {
  /* line-height: ; */
  text-align: center;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 200px;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.3); */
}

.nav {
  display: flex;
  width: 20%;
  height: 20%;
  /* background: #eee; */
  align-items: center;
  justify-content: center;
}
